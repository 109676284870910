<template>
	<v-list three-line class="mt-n4">
		<v-card flat class="card-item-view pt-4">
			<v-card-text class="py-0" color="#5F0CBA">
				<p v-html="articleMessage" class="mt-2 cont-txt"></p>
			</v-card-text>
		</v-card>
		<CommentItem
			v-for="(item, index) in specialItems"
			:notFirst="index > 0"
			:pk="replyPk"
			:sk="item"
			@openWriteReplyForm="openWriteReplyForm"
			:key="'replySpecial' + replyPk + item"
			@reduceReplyCount="reduceReplyCount"
			@loadingChk="no"
			@updateRecentOnDelete="updateRecentOnDelete"
		/>
		<CommentItem
			v-for="(item, index) in items"
			:notFirst="index > 0 || specialItems.length > 0"
			:pk="replyPk"
			:sk="item"
			@openWriteReplyForm="openWriteReplyForm"
			:key="'reply' + replyPk + item"
			@reduceReplyCount="reduceReplyCount"
			@loadingChk="reMore"
			@updateRecentOnDelete="updateRecentOnDelete"
		/>
		<v-bottom-sheet
			v-model="loginFlag"
			hide-overlay
			persistent
			no-click-animation
			content-class="elevation-0"
		>
			<v-card
				v-if="name"
				flat
				class="mx-0 rereplySheet"
				v-model="replyWriteSheet"
				ref="rereplyArea"
			>
				<v-card-actions>
					<div class="reply-txt">
						ㄴ {{ name }} 님에게 대댓글 남기는 중
					</div>
					<v-spacer></v-spacer>
					<v-btn
						icon
						width="20"
						height="20"
						@click="closeReplyLayer()"
					>
						<v-img
							width="20"
							src="../components/icons/close_alt_fill.png"
						></v-img>
					</v-btn>
				</v-card-actions>
			</v-card>
			<v-btn v-else style="display: none"></v-btn>
			<v-sheet class="text-center">
				<v-card elevation="0" class="pa-0" style="backgorund: #fff">
					<v-col
						cols="12"
						sm="12"
						md="12"
						class="px-2 py-2 comment-line"
						ref="replyArea"
					>
						<v-card-actions class="pa-0 align-end">
							<router-link to="/profile">
								<v-list-item-avatar>
									<v-img
										max-width="32"
										max-height="32"
										class="comment-profile"
										:src="$store.state.myInfo.thumb"
									></v-img> </v-list-item-avatar
							></router-link>
							<div class="comment-form">
								<v-textarea
									v-model="message"
									placeholder="댓글 쓰기..."
									color="#191923"
									outlined
									flat
									solo
									rows="1"
									auto-grow
									ref="replyTextarea"
								></v-textarea>
								<v-chip
									v-if="chip1"
									class="ma-1 ml-3"
									color="#F5F6FA"
									small
									close
									@click:close="chip1 = false"
								>
									{{ file.name }}
								</v-chip>
							</div>
							<v-file-input
								v-model="file"
								:rules="rules"
								small-chips
								show-size
								accept="image/png, image/jpeg"
								class="mt-0 mb-1 pt-0 btn-add-file"
							></v-file-input>
							<v-btn
								:loading="loading"
								:disabled="loading"
								text
								min-width="28"
								class="ml-0 px-2 btn-comment-submit"
								@click="replyWrite()"
								>게시</v-btn
							>
						</v-card-actions>
					</v-col>
				</v-card>
			</v-sheet>
		</v-bottom-sheet>
	</v-list>
</template>

<script>
import { writeReply, reReplyWrite } from "@/api/reply";
import { getCount } from "@/api/article";
import CommentItem from "@/components/comment/CommentItem.vue";

export default {
	name: "Comment",
	components: {
		CommentItem,
	},
	props: {
		focus: {
			type: Boolean,
		},
	},
	data() {
		return {
			specialItems: [],
			items: [],
			replyWriteSheet: true,
			loading: false,
			chip1: false,
			pk: "",
			name: "",
			message: "",
			file: undefined,
			previewFile: undefined,
			resizedFile: undefined,
			rules: [
				(value) => {
					if (value) {
						return value.size > 2000000
							? "File size should be less than 2 MB!"
							: true;
					}
					return true;
				},
			],
			firstTime: true,
			hideFlag: true,
			minHeight: 200,
			styleStr: "",

			replyIndex: 0,
			replyMoreLoading: false,
			channel: 0,
			replyHeight: "",
			replyCount: 0,
		};
	},
	computed: {
		storeItems() {
			return this.$store.state["replyItems_" + this.replyPk];
		},
		storeSpecialItems() {
			return (
				this.$store.state[
					"replyItemsSpecial_" + this.$route.params.articleSn
				] || []
			);
		},
		articleMessage() {
			return this.$store.state.postItem[
				this.$route.params.articleSn
			].message
				.replace(/\n/gi, "<br />")
				.replace(
					/#([0-9a-zA-Z가-힣]*)/g,
					'<a class="hash-tag" href="/tag/$1">#$1</a>'
				);
		},
		replyPk() {
			return this.$route.params.articleSn;
		},
	},
	watch: {
		storeItems: function (value) {
			this.items = value;
		},
		storeSpecialItems: function (value) {
			this.specialItems = value;
		},
		file: function () {
			if (this.file) {
				this.chip1 = true;
				this.resizedFile = this.zipFile(this.file);
			} else {
				this.chip1 = false;
			}
		},
		chip1: function () {
			if (!this.chip1) {
				this.file = undefined;
			}
		},
		replyHeight() {
			//console.log("변경 감지!!!");
			this.getReplyHeight();
		},
		replyCount: function (value) {
			this.$store.commit(
				"SET_TOP_NAVI_TITLE",
				"댓글 " +
					value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
					"개"
			);
		},
	},
	methods: {
		openWriteReplyForm(pk, name) {
			if (!this.loginFlag) {
				this.snackbar(
					"commonSnackbar",
					"로그인 후 글 등록 가능합니다.",
					"check",
					this.getReplyHeight()
				);
				return;
			}
			this.$refs.replyTextarea.focus();
			this.replyWriteSheet = true;
			this.pk = pk;
			this.name = name;
		},
		closeReplyLayer() {
			this.replyWriteSheet = false;
			this.pk = "";
			this.name = "";
		},
		no() {},
		async reMore(sk) {
			if (sk == this.items[this.items.length - 1]) {
				if (this.replyMoreLoading) {
					return;
				}
				this.replyMoreLoading = true;
				await this.$store.dispatch(
					"REPLY_ITEMS",
					this.$route.params.articleSn
				);
				this.replyMoreLoading = false;
			}
		},
		async replyWrite() {
			let snackbarMessage = this.loginFlag
				? this.message
					? this.message.length <= 1000
						? ""
						: "1000자를 초과하는 댓글은 작성할 수 없습니다."
					: "글은 반드시 등록해주셔야 합니다."
				: "로그인 후 글 등록 가능합니다.";
			if (snackbarMessage) {
				this.snackbar(
					"commonSnackbar",
					snackbarMessage,
					"check",
					this.getReplyHeight()
				);
				return;
			}

			this.loading = true;
			let params = {
				pk: this.pk ? this.pk : this.replyPk,
				message: this.message,
				files: this.resizedFile ? [this.resizedFile] : [],
			};
			let response = {};
			//대댓글 작성 케이스
			if (this.name) {
				response = await reReplyWrite(params);
				if (response.pk) {
					this.logWrite("replyWrite", "Click_커뮤니티_대댓글작성", {
						channel: this.channel,
						article: this.$route.params.articleSn,
						reply: this.pk,
					});
					this.clickTrac("424847", this.channel);
					this.$store.commit("SET_RE_REPLY_NEW_ITEMS", {
						pk: response.pk,
						items: [response],
						lek: "NO",
						direction: "desc",
					});
				}
				//댓글 작성 케이스
			} else {
				response = await writeReply(params);
				if (response.pk) {
					this.logWrite("replyWrite", "Click_커뮤니티_댓글작성", {
						channel: this.channel,
						article: this.$route.params.articleSn,
					});
					this.clickTrac("424848", this.channel);
					this.$store.commit("SET_REPLY_ITEMS", {
						pk: response.pk,
						items: [response],
						lek: "NO",
						direction: "desc",
					});

					this.replyCount++;
				}
			}
			this.loading = false;
			if (response.pk) {
				this.snackbar(
					"commonSnackbar",
					"등록이 완료되었습니다.",
					"check",
					58
					//높이값 구하면 대댓글에서는 더 높게 잡히는 이슈 확인
				);
				this.pk = "";
				this.name = "";
				this.message = "";
				this.file = undefined;
			} else if (response.code == "ERROR") {
				this.snackbar(
					"commonSnackbar",
					response.message,
					"info",
					this.getReplyHeight()
				);
			} else {
				this.snackbar(
					"commonSnackbar",
					"잠시 후 다시 시도해주세요",
					"info",
					this.getReplyHeight()
				);
			}
		},

		getChannelNum() {
			var chanList = this.$store.state.channel.items;
			for (var i = 0; i < chanList.length; i++) {
				if (
					this.$store.state.postItem[this.replyPk].author ==
					chanList[i].sn
				) {
					return chanList[i].code;
				}
			}
			return 0;
		},

		zipFile(file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const image = new Image();
				image.className = "img-item";
				image.src = e.target.result;
				image.onload = () => {
					// 이미지가 로드가 되면 리사이즈 함수가 실행.
					resize_image(image);
				};
			};

			const resize_image = (image) => {
				const dataUrl = this.canvasResizer(image);
				var result = this.dataURItoBlob(dataUrl);

				this.resizedFile = new File(
					[result],
					this.file.name.split(".")[0] + ".jpeg",
					{
						type: this.file.type,
					}
				);
			};

			reader.readAsDataURL(file);
		},

		dataURItoBlob(dataURI) {
			var binary = atob(dataURI.split(",")[1]);
			var array = [];
			for (var i = 0; i < binary.length; i++) {
				array.push(binary.charCodeAt(i));
			}
			return new Blob([new Uint8Array(array)], {
				type: "image/jpeg",
			});
		},
		canvasResizer(image) {
			let canvas = document.createElement("canvas"),
				max_size = 1280,
				// 최대 기준을 1280으로 잡음.
				width = image.width,
				height = image.height;

			if (width > height) {
				// 가로가 길 경우
				if (width > max_size) {
					height *= max_size / width;
					width = max_size;
				}
			} else {
				// 세로가 길 경우
				if (height > max_size) {
					width *= max_size / height;
					height = max_size;
				}
			}
			canvas.width = width;
			canvas.height = height;
			canvas.getContext("2d").drawImage(image, 0, 0, width, height);
			return canvas.toDataURL("image/jpeg");
		},

		getReplyHeight() {
			if (this.loginFlag == false) {
				return 56;
			}
			let reHeight = this.$refs.replyArea.scrollHeight;
			let rereHeight = 0;
			if (this.$refs.rereplyArea != undefined) {
				rereHeight = this.$refs.rereplyArea.$el.scrollHeight;
			}

			return reHeight + rereHeight;
		},
		reduceReplyCount() {
			this.replyCount--;
		},

		updateRecentOnDelete() {
			//첫 댓글을 삭제할 경우 recentReply 항목을 업데이트 해준다
			if (this.$store.state.article[this.replyPk]) {
				if (this.items[0]) {
					this.$set(
						this.$store.state.article[this.replyPk],
						"recentReply",
						this.items[0]
					);
				} else {
					this.$set(
						this.$store.state.article[this.replyPk],
						"recentReply",
						""
					);
				}
			}
		},
	},
	async mounted() {
		this.items = this.storeItems;
		this.specialItems = this.storeSpecialItems;
		this.channel = this.getChannelNum();
		this.$store.commit("SET_SELECTED_CHANNEL", this.channel);
		this.getReplyHeight();
		//초기 댓글수 조회
		const count = await getCount(this.$route.params.articleSn);
		this.replyCount = count.reply;
		this.logWrite("replyLink", "Click_커뮤니티_댓글페이지_이동", {
			channel: this.$route.params.channel,
			article: this.sk,
		});
		this.clickTrac("424844", this.$store.state["channel"].selected);
	},
	async created() {
		if (this.focus)
			setTimeout(() => {
				this.$refs.replyTextarea.focus();
			}, 500);
	},
};
</script>

<style scoped lang="scss">
.card-item-view {
	border-bottom: 1px solid #eaecf5;
	.tag-txt {
		font-size: 14px;
		color: #5f0cba;
	}
	.cont-txt {
		font-size: 14px;
		color: #424252;
	}
}
.theme--light.v-divider::v-deep {
	border-color: #f9fafc;
}
.btn-expend-more {
	font-size: 12px;
	font-weight: 600;
	color: #76768e;
	margin-left: 82px !important;
	margin-bottom: 16px;
}

// 대댓글 남기는 중
.rereplySheet::v-deep {
	height: 31px;
	background: #f9fafc;
	border-radius: 0;
	.reply-txt {
		font-size: 12px;
		font-weight: 400;
		color: #9595b2;
	}
	.v-card__actions {
		padding: 5px 16px;
	}
}

// 댓글쓰기 폼
.comment-profile::v-deep {
	padding-top: 0px;
}
.v-list-item__avatar {
	margin-bottom: 0px !important;
	margin-top: 0px !important;
	margin-right: 0px !important;
}
.comment-line {
	border-top: 1px solid #eaecf5;
	border-radius: 0 !important;
}
.comment-form::v-deep {
	flex: 1 0 auto;
	border-radius: 24px;
	min-height: 40px;
	align-items: flex-end !important;
	border: 1px solid #d2d4e5;
	text-align: left;
	&:focus,
	&:active,
	&:hover {
		border-color: #191923;
	}
	textarea {
		max-height: 84px;
		overflow: auto;
		border: 0;
	}
	.v-input__control {
		min-height: 40px !important;
	}
	.v-input__control > .v-input__slot {
		min-height: 40px;
		padding: 0 9px 0 12px !important;
		margin-bottom: 0 !important;
		background-color: transparent !important;
		.v-text-field__slot textarea {
			height: 28px;
			min-height: 28px;
			margin: 6px 0;
			outline: none;
		}
	}
	&.v-text-field--outlined.v-input--is-focused fieldset {
		border-width: 1px;
	}
	.v-icon.v-icon.v-icon--link {
		position: absolute;
		bottom: 10px;
	}
	.v-input__append-inner {
		.mdi-close::before {
			content: "";
			width: 20px;
			height: 20px;
			background-image: url("../components/icons/close_alt_fill.png");
			background-size: 100%;
		}
	}
	.v-text-field__details {
		display: none;
	}
	.v-text-field--outlined fieldset {
		border-width: 0;
	}
	.v-chip.v-size--small {
		position: relative;

		font-size: 16px;
		font-weight: 400;
		color: #9595b2;
		overflow: initial;
	}
	.v-chip .v-chip__content {
		display: inline-block;
		width: 125px;
		line-height: 28px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.v-chip .v-chip__close.v-icon {
		position: absolute;
		top: -8px;
		right: 0;
		max-width: 16px;
		max-height: 16px;
		margin-top: 0;
		margin-right: -7px;
	}
}
.v-dialog {
	box-shadow: none;
}

//첨부파일 아이콘
.btn-add-file::v-deep {
	width: 24px;
	margin-left: 8px;
	flex: 0 1 auto;
	.mdi-paperclip::before {
		content: "";
		width: 24px;
		height: 24px;
		background-image: url("../components/icons/file_add.png");
		background-size: cover;
	}
	.v-input__control {
		display: none;
	}
}
.btn-comment-submit {
	margin-bottom: 2px;
	font-size: 16px;
}

.snackbar-noti1::v-deep {
	.v-snack__wrapper {
		width: 100%;
		max-width: 100%;
		&.v-sheet {
			border-radius: 12px;
		}
		&.theme--dark {
			background-color: rgba(25, 25, 35, 0.74);
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
	}
}
</style>
