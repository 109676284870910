<template>
	<v-list-item dense class="comment-sub-item pl-0 ml-14">
		<Author :author="comment.author" :size="20" />
		<v-list-item-content>
			<v-list-item-title class="mt-2 mb-1 d-flex align-center">
				{{ member ? member.nickname : "noname" }}
				<Badge
					:commentAdminFlag="member ? member.adminFlag : 'N'"
					:author="comment.author"
				></Badge>
			</v-list-item-title>
			<v-list-item-subtitle
				:style="styleStr"
				ref="messageBox"
				style="min-height: 40px"
				v-html="message"
				@click="clickMore()"
			></v-list-item-subtitle>
			<div class="btn-expand-text" v-if="moreBtFlag" @click="clickMore()">
				{{ moreBtClickFlag ? "더보기" : "접기" }}
			</div>
			<div class="text-center mt-1" v-if="comment.images">
				<v-dialog
					v-model="dialog"
					width="500"
					fullscreen
					content-class="modal-wrap"
					transition="dialog-bottom-transition"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-img
							v-bind="attrs"
							v-on="on"
							:aspect-ratio="1"
							:max-width="120"
							:src="comment.images[0]"
							lazy-src="https://image.gsshop.com/mobile/mc/images/common/thumb_noimg_tv.jpg"
							class="rounded-lg"
						></v-img>
					</template>
					<v-card>
						<v-card-actions class="btn-modal-group py-0 pr-1">
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								min-width="24"
								text
								class="btn-modal-close"
								@click="dialog = false"
							>
								<v-img
									width="28"
									src="../../components/icons/close_modal.png"
								></v-img>
							</v-btn>
						</v-card-actions>
						<v-img :src="comment.images[0]"></v-img>
					</v-card>
				</v-dialog>
			</div>
			<RereMenu
				:pk="comment.pk"
				:sk="comment.sk"
				:author="comment.author"
				:loveitCount="loveitCount"
				:regDate="comment.regDate"
				@doLove="doLove()"
				@deleteReReply="deleteReReply"
			></RereMenu>
		</v-list-item-content>
		<v-card-actions class="mr-n4">
			<v-btn icon @click="doLove()">
				<v-img
					v-if="this.active == false"
					width="24"
					src="../icons/comment_zzim.png"
				></v-img>
				<v-img
					v-else
					width="24"
					src="../icons/comment_zzim_fill.png"
				></v-img>
			</v-btn>
		</v-card-actions>
	</v-list-item>
</template>

<script>
import { getReReplyCount, doLoveReReply, getLoveReReply } from "@/api/reply";
import Badge from "@/components/comment/Badge.vue";
import RereMenu from "@/components/comment/RereMenu.vue";
import Author from "@/components/common/AuthorType2.vue";

export default {
	name: "CommentItem",
	components: {
		Badge,
		RereMenu,
		Author,
	},
	props: {
		pk: String,
		sk: String,
		isMyReRe: Boolean,
	},
	data() {
		return {
			loveitCount: 0,
			styleStr: "",
			active: false,
			messageBoxHeight: 40,
			moreBtClickFlag: true,
			dialog: false,
			doLoveLoading: false,
		};
	},
	computed: {
		comment() {
			if (this.isMyReRe) {
				return this.$store.state["myReReplyItem"][this.pk].find(
					this.isSk
				);
			} else {
				return this.$store.state["reReplyItem_" + this.pk][this.sk];
			}
		},
		member() {
			return this.$store.state.member[this.comment.author];
		},
		moreBtFlag() {
			return this.messageBoxHeight >= 100;
		},
		message() {
			return (this.comment.message || "")
				.replace(/&/gi, "&amp;")
				.replace(/#/gi, "&#035;")
				.replace(/\(/gi, "&#040;")
				.replace(/\)/gi, "&#041;")
				.replace(/</gi, "&lt;")
				.replace(/>/gi, "&gt;")
				.replace(/"/gi, "&quot;")
				.replace(/'/gi, "&apos;")
				.replace(/\n/gi, "<br />");
		},
	},
	methods: {
		clickMore() {
			if (this.moreBtClickFlag) {
				this.moreBtClickFlag = false;
				this.styleStr = "-webkit-line-clamp: initial;";
			} else {
				this.moreBtClickFlag = true;
				this.styleStr = "-webkit-line-clamp: 5;";
			}
		},
		async doLove() {
			if (!this.loginFlag) {
				this.snackbar("commonSnackbar", "로그인 후 좋아요 가능합니다.");
				return;
			}
			//좋아요 버튼 연속 클릭 방지
			if (this.doLoveLoading) {
				return;
			}

			this.doLoveLoading = true;
			this.active = await doLoveReReply(this.comment.sk);
			this.loveitCount += this.active ? 1 : -1;
			this.doLoveLoading = false;
			this.clickTrac("424849", this.$store.state["channel"].selected);
		},
		popUpImage() {
			//사진 클릭 시 확대
			alert("pop Up!");
		},
		isSk(item) {
			if (item.sk === this.sk) {
				return true;
			}
		},
		deleteReReply(pk, sk) {
			this.$emit("deleteReReply", pk, sk, this.isMyReRe);
		},
	},
	async mounted() {
		this.$store.dispatch("GET_MEMBER_INFO", {
			type: "sn",
			sn: this.comment.author,
		});
		this.messageBoxHeight = this.$refs.messageBox.offsetHeight;
	},
	async created() {
		// 준(?)실시간성 데이터로 클라이언트에서는 매번 호출하지만 API Gateway의 캐시설정을 통해 처리 필요
		const count = await getReReplyCount(this.comment.sk);
		this.loveitCount = count.love;
		this.active = await getLoveReReply(this.comment.sk);
	},
};
</script>

<style scoped lang="scss">
.v-list-item--dense {
	.v-list-item__title {
		font-size: 1rem;
		font-weight: 600;
		color: #191923;
	}
	.v-list-item__subtitle {
		font-size: 14px;
		color: #191923 !important;
		line-height: 20px;
		-webkit-line-clamp: 5;
		font-weight: 400;
		min-height: auto !important;
	}
}
.comment-item {
	.v-btn__content {
		color: #76768e;
	}
	.button-box {
		color: #76768e;
		.v-btn {
			color: #76768e !important;
			min-width: 30px;
		}
	}
}

.comment-sub-item {
	border-top: 1px solid #eaecf5;
	.v-btn__content {
		color: #76768e;
	}
	.button-box {
		color: #76768e;
		.v-btn {
			color: #76768e !important;
			min-width: 30px;
		}
	}
	.v-btn.v-size--default {
		width: 40px;
		height: 40px;
	}
}
.comment-form::v-deep {
	border-radius: 24px;
	height: 40px;
	min-height: 40px;
	.v-input__control > .v-input__slot {
		min-height: 40px;
		padding: 0 9px 0 12px;
		.v-text-field__slot textarea {
			height: 28px;
			min-height: 28px;
			margin: 6px 0;
			outline: none;
		}
	}
	&.v-text-field--outlined.v-input--is-focused fieldset {
		border-width: 1px;
	}
	.v-input__append-inner {
		.mdi-close::before {
			content: "";
			width: 20px;
			height: 20px;
			background-image: url("../icons/close_alt_fill.png");
			background-size: 100%;
		}
	}
}
.snackbar-noti1::v-deep {
	.v-snack__wrapper {
		width: 100%;
		max-width: 100%;
		&.v-sheet {
			border-radius: 12px;
		}
		&.theme--dark {
			background-color: rgba(25, 25, 35, 0.74);
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
	}
}
.snackbar-noti2::v-deep {
	padding: 6px 16px 6px;
	background-color: #f9fafc;
	color: #9595b2;
	font-size: 0.7rem;
	.v-snack__wrapper {
		width: 100%;
		&.v-sheet {
			border-radius: 0;
		}
		&.theme--dark {
			margin: 0;
			background-color: #f9fafc;
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
		padding: 6px 16px 6px;
	}
}

.admin-sheet::v-deep {
	padding-bottom: 20px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	.admin-top {
		position: relative;
		padding: 30px 0 0;
		font-size: 16px;
		font-weight: 600;
		color: #191923;
		&:before {
			content: "";
			position: absolute;
			top: 8px;
			left: 50%;
			margin-left: -20px;
			display: block;
			width: 40px;
			height: 4px;
			border-radius: 4px;
			background-color: rgba(149, 149, 178, 0.3);
		}
	}
	.admin-other {
		margin: 0 24px;
		padding: 0;
		//    background-color: #F9FAFC;
		//    border-radius: 10px;
		border-bottom: 1px solid #eaecf5;
	}
}
.admin-button-group {
	display: flex;
	margin: -16px 0 0;
	.v-btn {
		border-radius: 10px;
	}
	.btn-cancel {
		width: calc(45% - 8px);
		margin-right: 8px;
		color: #191923 !important;
		font-weight: 600;
		border-color: #d2d4e5;
	}
	.btn-confirm {
		width: 55%;
		color: #191923 !important;
		font-weight: 600;
	}
}
.theme--light.v-list-item:hover::before {
	opacity: 0;
}

.form-sheet::v-deep {
	position: relative;
	padding-top: 30px;
	padding-bottom: 20px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	&:before {
		content: "";
		position: absolute;
		top: 8px;
		left: 50%;
		margin-left: -20px;
		display: block;
		width: 40px;
		height: 4px;
		border-radius: 4px;
		background-color: rgba(149, 149, 178, 0.3);
	}
}
.btn-expand-text {
	margin: 2px 0 0;
	line-height: 20px;
	color: #999;
	font-size: 14px;
}
</style>
