<template>
	<v-list-item-action-text class="button-box mt-1">
		<v-btn text small class="text-date pl-0">{{
			formatDate(regDate, "type2")
		}}</v-btn>
		<v-btn
			text
			small
			min-width="20"
			class="px-2"
			color="#76768E"
			@click="doLove()"
			>좋아요 {{ loveitCount }}{{ loveitCount > 0 ? "개" : "" }}</v-btn
		>
		<v-btn text small min-width="20" class="px-2" @click="writeReReply(sk)"
			>대댓글달기</v-btn
		>
		<v-btn
			v-if="isDeletable"
			text
			small
			min-width="20"
			class="px-2"
			@click="openDialog"
			>삭제</v-btn
		>
		<v-btn
			v-if="adminFlag"
			text
			small
			min-width="20"
			class="px-2"
			@click="openSetSpecial()"
			>{{ kind ? "해제" : "고정" }}</v-btn
		>
		<v-bottom-sheet class="adminSheet" v-model="setSpecialForm">
			<v-sheet class="admin-sheet text-center">
				<div class="admin-top">댓글 상단 고정</div>
				<v-radio-group v-model="radios">
					<v-list>
						<v-list-item
							link
							ripple
							class="admin-other text-left"
							@click="setClick('Notice')"
						>
							<v-list-item-title>일반 공지사항</v-list-item-title>
							<v-list-item-icon>
								<v-radio color="#abbf13" value="Notice">
								</v-radio>
							</v-list-item-icon>
						</v-list-item>
						<v-list-item
							link
							class="admin-other text-left"
							@click="setClick('PDPick')"
						>
							<v-list-item-title>PD Pick!</v-list-item-title>
							<v-list-item-icon>
								<v-radio color="#abbf13" value="PDPick">
								</v-radio>
							</v-list-item-icon>
						</v-list-item>
					</v-list>
				</v-radio-group>
				<v-row align="center" class="mx-6 admin-button-group">
					<v-btn
						outlined
						color="#D2D4E5"
						large
						class="btn-cancel"
						@click="setSpecialForm = false"
					>
						취소
					</v-btn>
					<v-btn
						depressed
						large
						color="#CAE72D"
						class="btn-confirm"
						@click="setSpecial('write')"
					>
						확인
					</v-btn>
				</v-row>
			</v-sheet>
		</v-bottom-sheet>
		<v-dialog v-model="deleteReplyDialog" max-width="290">
			<v-card class="popup-box">
				<v-card-text class="pt-6">
					댓글을 삭제하시겠습니까?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="btn-cancel"
						text
						@click="deleteReplyDialog = false"
					>
						취소
					</v-btn>
					<v-btn color="btn-confirm" text @click="deleteReply">
						확인
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-list-item-action-text>
</template>

<script>
import _ from "lodash";
import { setReplySpecial, deleteReply, deleteReplySpecial } from "@/api/reply";

export default {
	name: "Menu",
	props: {
		pk: String,
		sk: String,
		author: String,
		loveitCount: Number,
		regDate: Number,
		kind: String,
	},
	data() {
		return {
			radios: "Notice",
			setSpecialForm: false,
			onSubmitflag: false,
			deleteReplyDialog: false,
		};
	},
	computed: {
		isDeletable() {
			return this.isAuthor(this.author) || this.adminFlag;
		},
	},
	methods: {
		doLove() {
			this.$emit("doLove");
		},
		writeReReply() {
			this.$emit("writeReReply");
		},
		openDialog() {
			if (!this.isDeletable) {
				this.snackbar(
					"commonSnackbar",
					"삭제 권한이 없습니다.",
					"info"
				);
				return;
			}

			if (this.onSubmitflag) {
				return;
			}

			this.deleteReplyDialog = true;
		},
		async deleteReply() {
			this.onSubmitflag = true;
			let result = await deleteReply(
				this.pk.replace("reply#", ""),
				this.sk
			);
			if (result == undefined || result.status == "fail") {
				this.deleteReplyDialog = false;
				this.snackbar(
					"commonSnackbar",
					"댓글 삭제 중 오류가 발생하였습니다. 새로고침 후 다시 시도해 주세요."
				);
			} else if (result.status == "success") {
				this.deleteReplyDialog = false;
				this.snackbar("commonSnackbar", "삭제가 완료되었습니다.");
				let replys =
					this.$store.state[
						"replyItems_" + this.pk.replace("reply#", "")
					];
				let replyIndex = _.indexOf(replys, this.sk);
				replys.splice(replyIndex, 1);
				//댓글 총 갯수 감소
				this.$emit("reduceReplyCount");
				this.onSubmitflag = false;
				this.$emit("checkFirstAndEmit");
			}
		},
		setClick(type) {
			this.radios = type;
		},
		openSetSpecial() {
			if (this.kind) {
				this.setSpecial("delete");
				return;
			}
			this.setSpecialForm = !this.setSpecialForm;
		},
		async setSpecial(kind) {
			if (this.onSubmitflag) {
				return;
			}
			this.onSubmitflag = true;
			if (kind == "write") {
				let snackbarMessage = "";
				if (this.radios) {
					const response = await setReplySpecial(
						this.pk.replace("reply#", ""),
						this.sk,
						this.radios
					);
					snackbarMessage = response.result
						? "해당 댓글이 상단에 고정되었습니다."
						: response.message;
					if (response.result) {
						let replys =
							this.$store.state[
								"replyItems_" + this.pk.replace("reply#", "")
							];
						let replyIndex = _.indexOf(replys, this.sk);
						this.$store.state[
							"replyItem_" + this.pk.replace("reply#", "")
						][this.sk]["kind"] = this.radios;

						if (
							this.$store.state[
								"replyItemsSpecial_" +
									this.pk.replace("reply#", "")
							] == undefined
						) {
							this.$store.state[
								"replyItemsSpecial_" +
									this.pk.replace("reply#", "")
							] = [];
						}
						this.$store.state[
							"replyItemsSpecial_" + this.pk.replace("reply#", "")
						].unshift(this.sk);
						replys.splice(replyIndex, 1);
					}
				} else {
					snackbarMessage = "고정시킬 타입을 선택해주세요.";
				}
				this.setSpecialForm = false;
				this.snackbar("commonSnackbar", snackbarMessage, "info");
				this.onSubmitflag = false;
				return;
			}
			if (kind == "delete") {
				const response = await deleteReplySpecial(
					this.pk.replace("reply#", ""),
					this.sk
				);
				if (response.status == "fail") {
					this.snackbar(
						"commonSnackbar",
						"오류가 발생하였습니다. 새로고침 후 다시 시도해 주세요."
					);
				} else {
					let specialReplys =
						this.$store.state[
							"replyItemsSpecial_" + this.pk.replace("reply#", "")
						];
					let specialReplyIndex = _.indexOf(specialReplys, this.sk);
					specialReplys.splice(specialReplyIndex, 1);
					this.$store.state[
						"replyItem_" + this.pk.replace("reply#", "")
					][this.sk]["kind"] = undefined;
					this.$store.state[
						"replyItems_" + this.pk.replace("reply#", "")
					].unshift(this.sk);
					this.snackbar(
						"commonSnackbar",
						response.result
							? "해당 댓글이 상단고정 해제 되었습니다."
							: response.message,
						"check"
					);
					this.onSubmitflag = false;
					return;
				}
			}
		},
	},
};
</script>


<style scoped lang="scss">
.v-list-item--dense {
	.v-list-item__title {
		font-size: 1rem;
		font-weight: 600;
		color: #191923;
	}
	.v-list-item__subtitle {
		font-size: 14px;
		color: #191923 !important;
		line-height: 20px;
		-webkit-line-clamp: 5;
		font-weight: 400;
	}
}
.comment-item {
	.v-btn__content {
		color: #76768e;
	}
	.button-box {
		color: #76768e;
		.v-btn {
			color: #76768e !important;
			min-width: 30px;
		}
	}
	+ .btn-expend-more {
		// 댓글의 더보기 버튼 위치 스타일 재정의
		margin-left: 52px !important;
	}
}

.comment-sub-item {
	border-top: 1px solid #eaecf5;
	.v-btn__content {
		color: #76768e;
	}
	.button-box {
		color: #76768e;
		.v-btn {
			color: #76768e !important;
			min-width: 30px;
		}
	}
	.v-list-item__subtitle {
		-webkit-line-clamp: 5;
		font-size: 14px;
		font-weight: 400;
		color: #191923;
	}
}
.comment-form::v-deep {
	border-radius: 24px;
	height: 40px;
	min-height: 40px;
	.v-input__control > .v-input__slot {
		min-height: 40px;
		padding: 0 9px 0 12px;
		.v-text-field__slot textarea {
			height: 28px;
			min-height: 28px;
			margin: 6px 0;
			outline: none;
		}
	}
	&.v-text-field--outlined.v-input--is-focused fieldset {
		border-width: 1px;
	}
	.v-input__append-inner {
		.mdi-close::before {
			content: "";
			width: 20px;
			height: 20px;
			background-image: url("../icons/close_alt_fill.png");
			background-size: 100%;
		}
	}
}
.snackbar-noti1::v-deep {
	.v-snack__wrapper {
		width: 100%;
		max-width: 100%;
		&.v-sheet {
			border-radius: 12px;
		}
		&.theme--dark {
			background-color: rgba(25, 25, 35, 0.74);
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
	}
}
.snackbar-noti2::v-deep {
	padding: 6px 16px 6px;
	background-color: #f9fafc;
	color: #9595b2;
	font-size: 0.7rem;
	.v-snack__wrapper {
		width: 100%;
		&.v-sheet {
			border-radius: 0;
		}
		&.theme--dark {
			margin: 0;
			background-color: #f9fafc;
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
		padding: 6px 16px 6px;
	}
}

.admin-sheet::v-deep {
	padding-bottom: 20px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	.admin-top {
		position: relative;
		padding: 30px 0 0;
		font-size: 16px;
		font-weight: 600;
		color: #191923;
		&:before {
			content: "";
			position: absolute;
			top: 8px;
			left: 50%;
			margin-left: -20px;
			display: block;
			width: 40px;
			height: 4px;
			border-radius: 4px;
			background-color: rgba(149, 149, 178, 0.3);
		}
	}
	.admin-other {
		margin: 0 24px;
		padding: 0;
		//    background-color: #F9FAFC;
		//    border-radius: 10px;
		border-bottom: 1px solid #eaecf5;
	}
}
.admin-button-group {
	display: flex;
	margin: -16px 0 0;
	.v-btn {
		border-radius: 10px;
	}
	.btn-cancel {
		width: calc(45% - 8px);
		margin-right: 8px;
		color: #191923 !important;
		font-weight: 600;
		border-color: #d2d4e5;
	}
	.btn-confirm {
		width: 55%;
		color: #191923 !important;
		font-weight: 600;
	}
}
.theme--light.v-list-item:hover::before {
	opacity: 0;
}

.form-sheet::v-deep {
	position: relative;
	padding-top: 30px;
	padding-bottom: 20px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	&:before {
		content: "";
		position: absolute;
		top: 8px;
		left: 50%;
		margin-left: -20px;
		display: block;
		width: 40px;
		height: 4px;
		border-radius: 4px;
		background-color: rgba(149, 149, 178, 0.3);
	}
}

// 대댓글의 더보기 버튼 스타일
.btn-expend-more {
	font-size: 12px;
	font-weight: 600;
	color: #76768e;
	margin-top: -5px;
	margin-left: 82px !important;
	margin-bottom: 12px;
}
.btn-expand-text {
	margin: 2px 0 0;
	line-height: 20px;
	color: #8f8eab;
	font-size: 14px;
}
.text-date {
	cursor: default;
}
</style>
