<template>
	<v-list-item-avatar :size="size" class="mr-2">
		<v-img :src="thumbnail" :alt="nickname" to="/profile"></v-img>
	</v-list-item-avatar>
</template>

<script>
export default {
	name: "AuthorType2",
	props: {
		author: String,
		size: Number,
	},
	computed: {
		member() {
			return this.$store.state.member[this.author];
		},
		thumbnail() {
			return this.member
				? this.member.thumb
				: "https://gspingpong.s3.ap-northeast-2.amazonaws.com/images/unknown/001.png";
		},
		nickname() {
			return this.member ? this.member.nickname : "noname";
		},
	},
};
</script>
