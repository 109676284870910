<template>
	<section
		:style="'min-height:' + minHeight + 'px'"
		v-intersect="{
			handler: onIntersect,
			options: {
				threshold: [0],
			},
		}"
	>
		<div>
			<section v-show="hideFlag" class="bg-white">
				<v-divider v-if="notFirst" class="mx-4 border-gray"></v-divider>
				<v-list-item dense class="comment-item">
					<Author :author="comment.author" :size="32" />
					<v-list-item-content>
						<v-list-item-title
							class="mt-2 mb-1 d-flex align-center"
						>
							{{ member ? member.nickname : "noname" }}
							<Badge
								:commentAdminFlag="
									member ? member.adminFlag : 'N'
								"
								:kind="comment.kind"
								:author="comment.author"
							></Badge>
						</v-list-item-title>
						<v-list-item-subtitle
							:style="styleStr"
							ref="messageBox"
							style="min-height: 40px"
							v-html="message"
							@click="clickMore()"
						></v-list-item-subtitle>
						<div
							class="btn-expand-text"
							v-if="moreBtFlag"
							@click="clickMore()"
						>
							{{ moreBtClickFlag ? "더보기" : "접기" }}
						</div>
						<div class="text-center mt-1" v-if="comment.images">
							<v-dialog
								v-model="dialog"
								width="100%"
								fullscreen
								transition="dialog-bottom-transition"
								content-class="modal-wrap"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-img
										v-bind="attrs"
										v-on="on"
										:aspect-ratio="1"
										:max-width="120"
										:src="comment.images[0]"
										lazy-src="https://image.gsshop.com/mobile/mc/images/common/thumb_noimg_tv.jpg"
										class="rounded-lg"
									></v-img>
								</template>
								<v-card>
									<v-card-actions
										class="btn-modal-group py-0 pr-1"
									>
										<v-spacer></v-spacer>
										<v-btn
											color="primary"
											min-width="24"
											text
											class="btn-modal-close"
											@click="dialog = false"
										>
											<v-img
												width="28"
												src="../../components/icons/close_modal.png"
											></v-img>
										</v-btn>
									</v-card-actions>
									<v-img :src="comment.images[0]"></v-img>
								</v-card>
							</v-dialog>
						</div>
						<!-- lazy-src 이미지 변경 필수 -->
						<Menu
							:pk="comment.pk"
							:sk="comment.sk"
							:author="comment.author"
							:loveitCount="loveitCount"
							:regDate="comment.regDate"
							:kind="comment.kind"
							@reduceReplyCount="reduceReplyCount"
							@doLove="doLove"
							@writeReReply="writeReReply()"
							@checkFirstAndEmit="checkFirstAndEmit"
						></Menu>
					</v-list-item-content>
					<v-card-actions>
						<v-btn icon @click="doLove()">
							<v-img
								v-if="this.active == false"
								width="24"
								src="../icons/comment_zzim.png"
							></v-img>
							<v-img
								v-else
								width="24"
								src="../icons/comment_zzim_fill.png"
							></v-img>
						</v-btn>
					</v-card-actions>
				</v-list-item>
				<section v-for="(item, index) in currReReplys" :key="index">
					<ReCommentItem
						:pk="sk"
						:sk="item"
						@deleteReReply="deleteReReply"
						:isMyReRe="false"
						v-show="rereShowFlag || index < 2"
					></ReCommentItem>
				</section>
				<section v-for="(item, idx) in myReReplys" :key="`my-${idx}`">
					<ReCommentItem
						:pk="sk"
						:sk="item.sk"
						@deleteReReply="deleteReReply"
						:isMyReRe="true"
					></ReCommentItem>
				</section>
				<!-- 더보기 버튼 -->
				<v-btn
					v-if="reReplyMoreBtFlag"
					:key="'button'"
					small
					text
					class="btn-expend-more pr-1 pl-0 ml-14"
					@click="rereMore()"
				>
					<v-img
						width="16"
						class="mr-1"
						:src="
							require('@/components/icons/expend_' +
								(reReplyOpenFlag ? 'less' : 'more') +
								'.png')
						"
					></v-img>
					{{ getReReplyText }}
				</v-btn>
			</section>
		</div>
	</section>
</template>

<script>
import _ from "lodash";
import {
	getReplyCount,
	doLoveReply,
	getLoveReply,
	reReplyItems,
} from "@/api/reply";
import Badge from "@/components/comment/Badge.vue";
import Menu from "@/components/comment/Menu.vue";
import Author from "@/components/common/AuthorType2.vue";
import ReCommentItem from "@/components/comment/RecommentItem.vue";

export default {
	name: "CommentItem",
	components: {
		Badge,
		Menu,
		Author,
		ReCommentItem,
	},
	props: {
		notFirst: Boolean,
		pk: String,
		sk: String,
	},
	data() {
		return {
			loveitCount: 0,
			commentCount: 0,
			dialog: false,
			styleStr: "",
			active: false,
			messageBoxHeight: 40,
			moreBtClickFlag: true,
			hideFlag: true,
			minHeight: 50,

			currReReplys: [],
			//myReReplys: [],
			reReplyOpenFlag: false,
			reReplyIndex: 0,
			rereMoreLoading: false,
			rereLastEvaluatedKey: "",
			rereShowFlag: true,

			tempLoadingFlag: true,
			doLoveLoading: false,
		};
	},
	computed: {
		getReReplyText() {
			const count = this.commentCount - this.currReReplys.length;
			if (count > 0) {
				return "대댓글 " + count + "개 더보기";
			} else if (!this.rereShowFlag) {
				return "대댓글 " + (this.commentCount - 2) + "개 더보기";
			} else {
				return "대댓글 접기";
			}
		},
		reReplys() {
			return this.$store.state["reReplyItems_" + this.comment.sk] || [];
		},
		comment() {
			return this.$store.state["replyItem_" + this.pk][this.sk];
		},
		member() {
			return this.$store.state.member[this.comment.author];
		},
		moreBtFlag() {
			return this.messageBoxHeight >= 100;
		},
		reReplyMoreBtFlag() {
			return this.commentCount > 2;
			// return this.commentCount > 2 && 현재 보여지는 리스트 수 < this.commentCount;
		},
		myReReplys() {
			return this.$store.state["myReReplyItem"][this.sk];
		},
		message() {
			return (this.comment.message || "")
				.replace(/&/gi, "&amp;")
				.replace(/#/gi, "&#035;")
				.replace(/\(/gi, "&#040;")
				.replace(/\)/gi, "&#041;")
				.replace(/</gi, "&lt;")
				.replace(/>/gi, "&gt;")
				.replace(/"/gi, "&quot;")
				.replace(/'/gi, "&apos;")
				.replace(/\n/gi, "<br />");
		},
	},
	methods: {
		clickMore() {
			if (this.moreBtClickFlag) {
				this.moreBtClickFlag = false;
				this.styleStr = "-webkit-line-clamp: initial;";
			} else {
				this.moreBtClickFlag = true;
				this.styleStr = "-webkit-line-clamp: 5;";
			}
		},
		async doLove() {
			if (!this.loginFlag) {
				this.snackbar(
					"commonSnackbar",
					"로그인 후 좋아요 가능합니다.",
					"check"
				);

				return;
			}
			//좋아요 버튼 연속 클릭 방지
			if (this.doLoveLoading) {
				return;
			}

			this.doLoveLoading = true;
			this.active = await doLoveReply(this.comment.sk);
			this.loveitCount += this.active ? 1 : -1;
			this.doLoveLoading = false;
			this.clickTrac("424846", this.$store.state["channel"].selected);
		},
		writeReReply() {
			this.$emit(
				"openWriteReplyForm",
				this.comment.sk,
				this.member ? this.member.nickname : "noname"
			);
		},
		unit(arrayLength, count) {
			return Array.from(
				{
					length: arrayLength < count ? arrayLength : count,
				},
				(v, i) => i
			);
		},
		async onIntersect(entries) {
			this.minHeight = this.$el.clientHeight;
			this.hideFlag = entries[0].isIntersecting;
			if (entries[0].isIntersecting) {
				this.$emit("loadingChk", this.comment.sk);
				if (this.tempLoadingFlag) {
					this.tempLoadingFlag = false;
					// 댓글 작성자 정보 가져오기
					this.$store.dispatch("GET_MEMBER_INFO", {
						type: "sn",
						sn: this.comment.author,
					});
					// 댓글의 카운트 썸 가져오기
					const count = await getReplyCount(this.comment.sk);
					this.loveitCount = count.love;
					this.commentCount = count.reply;
					// 내 좋아요 여부 가져오기
					this.active = await getLoveReply(this.comment.sk);
					// 대댓글 목록 가져오기
					await this.$store.dispatch(
						"RE_REPLY_ITEMS",
						this.comment.sk
					);

					this.unit(this.reReplys.length, 2).forEach(() => {
						this.currReReplys.push(
							this.reReplys[this.reReplyIndex++]
						);
					});
				}
			}
		},

		async rereMore() {
			//대댓글이 열려있으면 더보기를 접는다
			if (this.reReplyOpenFlag == true) {
				this.rereShowFlag = false;
				this.reReplyOpenFlag = false;
				return;
			}

			//아직 더 보여줄 대댓글이 남았다면 그 남은 대댓글의 수에서 5개만큼 더 보여준다
			if (this.reReplys.length > this.reReplyIndex) {
				let count = this.reReplys.length - this.reReplyIndex;
				let subUnit = Array.from(
					{
						length: count > 5 ? 5 : count,
					},
					(v, i) => i
				);
				subUnit.forEach(() => {
					this.currReReplys = _.concat(
						this.currReReplys,
						this.reReplys[this.reReplyIndex++]
					);
				});
			} else if (this.currReReplys.length == this.commentCount) {
				this.rereShowFlag = !this.rereShowFlag;
			} else {
				if (this.rereLastEvaluatedKey) {
					if (this.rereMoreLoading) {
						return;
					}
					this.rereMoreLoading = true;
					// await this.$store.dispatch("FETCH_MORE_ITEMS");
					const reReplysMore = await reReplyItems(
						this.comment.sk,
						this.rereLastEvaluatedKey
					);
					this.rereLastEvaluatedKey = reReplysMore.LastEvaluatedKey;
					this.reReplys = this.reReplys.concat(reReplysMore.items);
					this.currReReplys = _.concat(
						this.currReReplys,
						this.reReplys[this.reReplyIndex++]
					);
					this.rereMoreLoading = false;
				}
			}

			if (this.getReReplyText == "대댓글 접기") {
				this.reReplyOpenFlag = true;
			} else {
				this.reReplyOpenFlag = false;
			}
		},
		reduceReplyCount() {
			this.$emit("reduceReplyCount");
		},
		checkFirstAndEmit() {
			//첫 댓글을 삭제할 경우 recentReply 항목을 업데이트 해준다
			if (this.notFirst == false) {
				this.$emit("updateRecentOnDelete");
			}
		},

		deleteReReply(pk, sk, isMyReReply) {
			let reReplyIndex = -1;
			let currIndex = -1;
			let reReplys = [];
			let currReReplys = [];
			//내가 방금 작성한 대댓글일 경우
			if (isMyReReply == true) {
				reReplys =
					this.$store.state[
						"myReReplyItems_" + pk.replace("reReply#", "")
					];
				currReReplys = this.myReReplys;
			} else {
				reReplys =
					this.$store.state[
						"reReplyItems_" + pk.replace("reReply#", "")
					];
				currReReplys = this.currReReplys;
				this.commentCount--;
			}
			//state 에서 대댓글 제거
			reReplyIndex = reReplys.findIndex((v) => v == sk);
			reReplys.splice(reReplyIndex, 1);
			//현재 대댓글 목록에서 대댓글 제거
			currIndex = currReReplys.findIndex((v) => v == sk);
			currReReplys.splice(currIndex, 1);
		},
	},

	created() {},
	async mounted() {
		this.messageBoxHeight = this.$refs.messageBox.offsetHeight;
	},
};
</script>

<style scoped lang="scss">
// 이미지 모달창

.v-list-item--dense {
	.v-list-item__title {
		font-size: 1rem;
		font-weight: 600;
		color: #191923;
	}
	.v-list-item__subtitle {
		font-size: 14px;
		color: #191923 !important;
		line-height: 20px;
		-webkit-line-clamp: 5;
		font-weight: 400;
		min-height: auto !important;
	}
}
.comment-item {
	.v-btn__content {
		color: #76768e;
	}
	.button-box {
		color: #76768e;

		.v-btn {
			color: #76768e !important;
			min-width: 30px;
		}
	}
	+ .btn-expend-more {
		// 댓글의 더보기 버튼 위치 스타일 재정의
		margin-left: 52px !important;
	}
	.v-btn--icon.v-size--default {
		width: 40px;
		height: 40px;
	}
	.v-card__actions {
		width: 40px;
	}
}

.comment-sub-item {
	border-top: 1px solid #eaecf5;
	.v-btn__content {
		color: #76768e;
	}
	.button-box {
		color: #76768e;
		.v-btn {
			color: #76768e !important;
		}
	}
	.v-list-item__subtitle {
		-webkit-line-clamp: 5;
		font-size: 14px;
		font-weight: 400;
		color: #191923;
	}
}
.comment-form::v-deep {
	border-radius: 24px;
	height: 40px;
	min-height: 40px;
	.v-input__control > .v-input__slot {
		min-height: 40px;
		padding: 0 9px 0 12px;
		.v-text-field__slot textarea {
			height: 28px;
			min-height: 28px;
			margin: 6px 0;
			outline: none;
		}
	}
	&.v-text-field--outlined.v-input--is-focused fieldset {
		border-width: 1px;
	}
	.v-input__append-inner {
		.mdi-close::before {
			content: "";
			width: 20px;
			height: 20px;
			background-image: url("../icons/close_alt_fill.png");
			background-size: 100%;
		}
	}
}
.snackbar-noti1::v-deep {
	.v-snack__wrapper {
		width: 100%;
		max-width: 100%;
		&.v-sheet {
			border-radius: 12px;
		}
		&.theme--dark {
			background-color: rgba(25, 25, 35, 0.74);
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
	}
}
.snackbar-noti2::v-deep {
	padding: 6px 16px 6px;
	background-color: #f9fafc;
	color: #9595b2;
	font-size: 0.7rem;
	.v-snack__wrapper {
		width: 100%;
		&.v-sheet {
			border-radius: 0;
		}
		&.theme--dark {
			margin: 0;
			background-color: #f9fafc;
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
		padding: 6px 16px 6px;
	}
}

.admin-sheet::v-deep {
	padding-bottom: 20px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	.admin-top {
		position: relative;
		padding: 30px 0 0;
		font-size: 16px;
		font-weight: 600;
		color: #191923;
		&:before {
			content: "";
			position: absolute;
			top: 8px;
			left: 50%;
			margin-left: -20px;
			display: block;
			width: 40px;
			height: 4px;
			border-radius: 4px;
			background-color: rgba(149, 149, 178, 0.3);
		}
	}
	.admin-other {
		margin: 0 24px;
		padding: 0;
		//    background-color: #F9FAFC;
		//    border-radius: 10px;
		border-bottom: 1px solid #eaecf5;
	}
}
.admin-button-group {
	display: flex;
	margin: -16px 0 0;
	.v-btn {
		border-radius: 10px;
	}
	.btn-cancel {
		width: calc(45% - 8px);
		margin-right: 8px;
		color: #191923 !important;
		font-weight: 600;
		border-color: #d2d4e5;
	}
	.btn-confirm {
		width: 55%;
		color: #191923 !important;
		font-weight: 600;
	}
}
.theme--light.v-list-item:hover::before {
	opacity: 0;
}

.form-sheet::v-deep {
	position: relative;
	padding-top: 30px;
	padding-bottom: 20px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	&:before {
		content: "";
		position: absolute;
		top: 8px;
		left: 50%;
		margin-left: -20px;
		display: block;
		width: 40px;
		height: 4px;
		border-radius: 4px;
		background-color: rgba(149, 149, 178, 0.3);
	}
}

// 대댓글의 더보기 버튼 스타일
.btn-expend-more {
	font-size: 12px;
	font-weight: 600;
	color: #76768e;
	margin-top: -5px;
	margin-left: 82px !important;
	margin-bottom: 12px;
}
.btn-expand-text {
	margin: 2px 0 0;
	line-height: 20px;
	color: #999;
	font-size: 14px;
}
.border-gray.v-divider {
	border-color: #eaecf5;
}
</style>
